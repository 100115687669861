import {apiClient} from 'api/http';

function search(body, query) {
  return apiClient.post(`/entities/search/?${query}`, body);
}

function getCountries(body) {
  return apiClient.post('/entities/countries/', body);
}

function count() {
  return apiClient.post('/entities/count/');
}

function details(tritonId) {
  return apiClient.get(`/entities/${tritonId}/`);
}

function network(body) {
  return apiClient.post('/entities/network/', body);
}

function networkWrapped(body) {
  return apiClient.post('/entities/network/wrapped/', body);
}

function favorite(body) {
  return apiClient.post('/entities/favorite/', body);
}

function unfavorite(body) {
  return apiClient.post('/entities/unfavorite/', body);
}

function favorites() {
  return apiClient.get('/entities/favorites/');
}

function recentlyViewed() {
  return apiClient.get('/entities/recently-viewed/');
}

function autocomplete(search) {
  return apiClient.get(`/entities/autocomplete/?search=${search}`);
}

export const entityDownload = (triton_id) => {
  return apiClient.get(`/entities/download?id=${triton_id}`);
};

const getSkylightUrlByMMSI = (mmsi) => {
  return apiClient.get(`/entities/skylight-search/${mmsi}/`);
};

export const entitiesService = {
  search,
  count,
  details,
  getCountries,
  network,
  networkWrapped,
  favorite,
  unfavorite,
  favorites,
  recentlyViewed,
  entityDownload,
  getSkylightUrlByMMSI,
  autocomplete
};
