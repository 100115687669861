import {useState, useEffect, useRef, useMemo} from 'react';
import axios from 'axios';
import {moment} from 'utils/time';
import {httpErrorLighthouse} from '../stores/contexts/HttpErrorStore';

export function useApi({
  service = null,
  payload = null,
  queryParams = null,
  immediate = true,
  callback = null,
  disableErrorHandling = false
} = {}) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusText, setStatusText] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(moment().valueOf());
  const [ignoreDataUpdates, setIgnoreDataUpdates] = useState(true);
  const mountedRef = useRef(true);
  const abortControllerRef = useRef(null);

  const execute = useMemo(() => (payload = null, queryParams = null) => {
    setLoading(true);
    let queryString = '';

    if (queryParams) {
      if (typeof (queryParams) === 'string' || Array.isArray(queryParams)) {
        queryString = queryParams;
      } else {
        Object.entries(queryParams).forEach(([key, value]) => {
          if (key === 'ordering') {
            value = value.sort === 'asc' ? value.field : `-${value.field}`;
          }

          queryString = queryString.concat(`${key}=${value}&`);
        });
      }
    }

    const output = service(payload, queryString)
      .then(([abortController, requestPromise]) => {
        abortControllerRef.current = abortController;
        return requestPromise;
      })
      .then((response) => {
        abortControllerRef.current = null;
        if (!mountedRef.current) {
          return null;
        } else {
          setData(response.data);
          setStatus(response.status);
          setStatusText(response.statusText);
          setError(null);
          setLoading(false);

          return response.data;
        }
      })
      .catch((error) => {
        abortControllerRef.current = null;

        if (!axios.isCancel(error) && !disableErrorHandling) {
          httpErrorLighthouse.manageHttpResponse(error);
        }

        if (!mountedRef.current) {
          return null;
        } else {
          setData(null);
          setStatus(error?.response?.status);
          setStatusText(null);
          setError(error);
          setLoading(false);

          return error;
        }
      });

    if (callback) {
      callback(output);
    }

    return output;
  }, [
    service,
    queryParams,
    reloadTrigger
  ]);

  useEffect(() => {
    if (ignoreDataUpdates) {
      if (data) {
        setIgnoreDataUpdates(false);
      }
      return;
    }

    if (callback) {
      callback();
    }
  }, [data]);

  const reload = () => {
    setReloadTrigger(moment().valueOf());
  };

  const reset = () => {
    setData(null);
    setStatus(null);
    setStatusText(null);
    setLoading(null);
    setError(null);
    setReloadTrigger(moment().valueOf());
  };

  const update = (newData) => {
    setData(newData);
  };

  useEffect(() => {
    if (immediate) {
      execute(payload, queryParams);
    }
  }, [immediate, execute]);

  useEffect(() => {
    function temp() {
      mountedRef.current = false;
    }

    return temp;
  }, [mountedRef]);

  const abort = () => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = null;
  };

  return {
    execute,
    data,
    status,
    statusText,
    loading,
    setLoading,
    error,
    reload,
    reloadTrigger,
    reset,
    update,
    abort
  };
}
